import type { UseFormReturn } from 'react-hook-form';
import type { Action, Condition } from './notificationsystem';
import { OnSubmitFunc } from '@/types/form';

export interface NotificationSystemFormFields {
  name: string;
  query: string;
  schedules: string;
  actions: Array<Action>;
  conditions: Array<Condition>;
}

export interface FieldsetProps extends UseFormReturn<NotificationSystemFormFields> {
}

export type NotificationSubmit = OnSubmitFunc<NotificationSystemFormFields>;

export const INTERVALS = {
  HOURS: 'Hours',
  DAYS: 'Days',
} as const;

export type IntervalNames = typeof INTERVALS[keyof typeof INTERVALS];
