import { useState, type ChangeEvent } from 'react';
import { Group, NumberInput } from '@mantine/core';

import MercuryNativeSelect from '@/components/content/form/components/NativeSelect';
import BaseInput from '@/components/content/form/components/BaseInput';

import { parseInterval, stringifyInterval } from './parsers';
import { type IntervalNames, INTERVALS } from '../../types';

interface IntervalProps {
  onChange: (value: string | null) => void
  error?: string;
  value: string;
}

const Interval = ({ value, error, onChange }: IntervalProps) => {
  const parsed = parseInterval(value);
  const [frequency, setFrequency] = useState(parsed?.frequency ?? INTERVALS.HOURS);

  const onFrequencyChange = (val: string | number) => {
    onChange(val ? stringifyInterval(val, frequency) : null);
  };

  const onIntervalChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const val = e.target.value as IntervalNames;
    setFrequency(val as IntervalNames);

    if (parsed?.value) {
      onChange(stringifyInterval(parsed?.value, val as IntervalNames));
    }
  };

  const field = {
    [INTERVALS.HOURS]: {
      min: 1,
      description: 'Min amount of hours is 1',
    },
    [INTERVALS.DAYS]: {
      min: 1,
      description: 'Min amount of days is 1',
    },
  }[frequency];

  return (
    <Group gap={5} align="flex-start" mt={10}>
      <BaseInput
        element={NumberInput}
        name="value"
        error={error}
        label="Every"
        description={field?.description}
        size="xs"
        value={parsed?.value}
        wrapperProps={{ styles: { root: { flex: '1' } } }}
        onChange={onFrequencyChange}
        min={field?.min}
        clampBehavior="strict"
        allowNegative={false}
        allowDecimal={false}
      />

      <MercuryNativeSelect
        name="frequency"
        label="Frequency"
        data={Object.values(INTERVALS)}
        value={frequency}
        onChange={onIntervalChange}
        disabled={!parsed?.value}
        size="xs"
      />
    </Group>
  );
};

export default Interval;
