import News from '@/features/news/News';
import { useAuth } from '@/hooks/useAuth';
import { getTrials } from '@/utils/hasTrials';
import { formatIsoDate } from '@/utils/date';

const TrialsModal = () => {
  const { userInfo } = useAuth();
  const trials = getTrials(userInfo.entitlements_trial).map((t) => (
    `<p><b>${t.name}</b>: Ends on ${formatIsoDate(t.end_at ?? '')}</p>`
  ));

  return (
    <News newsItem={{
      title: 'We got news for you!',
      content: `
      <p>You have been granted access to the following trials:</p>
      ${trials.join('')}

      <p>You can always check which trials you have active on your subscriptions
       page accessible via your profile menu</p>
      `,
    }}
    />
  );
};

export default TrialsModal;
