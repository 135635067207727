import { useMemo } from 'react';
import type { ModalProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import Modal from '../components/content/modal/Modal';

interface UseModalProps {
  onClose?: VoidFunction;
  initialOpenState?: boolean;
  once?: string;
}

export const useModal = ({ onClose, initialOpenState, once }: UseModalProps = {}) => {
  const [isModalOpen, { open, close }] = useDisclosure(initialOpenState);

  const getIsOpenedOnce = () => once && window.sessionStorage.getItem(once);

  const openModal = () => {
    if (!getIsOpenedOnce()) {
      open();
    }

    if (once && !getIsOpenedOnce()) {
      window.sessionStorage.setItem(once, 'opened');
    }
  };

  const closeModal = () => {
    close();

    if (onClose) {
      onClose();
    }
  };

  const modalProps: ModalProps = useMemo(() => ({
    onClose: closeModal,
    opened: isModalOpen,
    centered: true,
  }), [isModalOpen]);

  return {
    Modal,
    modalProps,
    openModal,
    forceOpenModal: open,
    closeModal,
  };
};
