import { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';

import { FREQUENCY, type FrequencyNames, type FieldsetProps } from '../../types';
import { parseInitialSchedule } from './parsers';

const FIELD_NAME = 'schedules';

interface UseNotificationScheduleProps {
  control: FieldsetProps['control'];
  setValue: FieldsetProps['setValue'];
  resetField: FieldsetProps['resetField'];
}

const DEFAULTS = {
  [FREQUENCY.INTERVAL]: '0 */1 * * *',
  [FREQUENCY.DAILY]: '0 0 * * *',
  [FREQUENCY.MONTHLY]: '0 0 1 * *',
  [FREQUENCY.WEEKLY]: null,
};

export const useNotificationSchedule = ({ control, setValue, resetField }: UseNotificationScheduleProps) => {
  const { field, fieldState, formState } = useController({ control, name: FIELD_NAME });
  const parsedValue = parseInitialSchedule(field.value as string);
  const [frequency, setFrequency] = useState(parsedValue?.frequency ?? '');

  const error = fieldState.error?.message || formState.errors[FIELD_NAME]?.message;

  const onChangeHandler = useCallback((val?: string | number | null) => {
    if (!val) {
      resetField(FIELD_NAME);
      return;
    }

    field.onChange(val);
  }, []);

  const changeFrequency = useCallback((val: FrequencyNames) => {
    setFrequency(val);

    if (DEFAULTS[val]) {
      setValue(FIELD_NAME, DEFAULTS[val]);
      return;
    }

    resetField(FIELD_NAME);
  }, []);

  return {
    field,
    frequency,
    error,
    onChangeHandler,
    changeFrequency,
  };
};
