import { Box, Flex, Stack, Text } from '@mantine/core';

// Components
import TableWrapper from '@/features/tablewrapper/TableWrapper';
import PillsList from '@/components/content/pills/PillsList';
import { MercuryCol, MercuryGrid } from '@/components/grid/Grid';
import Coin from '@/components/layout/nav/topbar/Coin';

// Hooks
import { useAuth } from '@/hooks/useAuth';

// Api
import { basicTableNormalize } from '@/features/tablewrapper/services/tableTransforms';

// Table
import { subscriptionsTable } from './subscriptions.table';
import { MercuryTableProvider } from '@/features/tablewrapper/MercuryTableContext';
import { LOW_QUOINS } from '@/constants';

// Misc
import { settingsKeys } from '../../services/keys';
import { formatIsoDate } from '@/utils/date';

import type { PillProps } from '@/components/content/pills/types';

const SubscriptionsInfo = () => {
  const { userInfo: user } = useAuth();
  const trials = user.entitlements_trial.filter((t) => !t.is_expired);

  return (
    <MercuryGrid>
      <MercuryCol>
        <Stack gap={25}>
          <Flex direction={{ base: 'column', m: 'row' }} wrap="nowrap" gap={{ base: 5, m: 20 }}>
            <Flex component="span" flex="40%">Modules subscribed to</Flex>
            <Box w="100%">
              <PillsList
                pills={user.entitlements}
                size="big"
                pillProps={user.entitlements_trial.reduce((accu, curr) => {
                  accu[curr.name] = {
                    bg: 'var(--quo-noir-75)',
                    color: 'white',
                  };

                  return accu;
                }, {} as PillProps)}
              />
            </Box>
          </Flex>

          {trials.length > 0 && (
            <Flex direction={{ base: 'column', m: 'row' }} wrap="nowrap" gap={{ base: 5, m: 20 }}>
              <Flex component="span" flex="40%">Modules with active trial</Flex>
              <Box w="100%">
                <PillsList
                  customKeys={trials.map((t) => t.name)}
                  pills={trials.map((t) => (
                    <div>
                      <Text>{t.name}</Text>
                      <Text size="xs">Ends on: {formatIsoDate(t.end_at ?? '', true)}</Text>
                    </div>
                  ))}
                  size="big"
                />
              </Box>
            </Flex>
          )}

          <Flex direction={{ base: 'column', m: 'row' }} wrap="nowrap" gap={{ base: 5, m: 20 }}>
            <Flex component="span" flex="40%">Credits available</Flex>

            <Box w="100%">
              <Coin value={user.quoins.available} lowValue={LOW_QUOINS} />
            </Box>
          </Flex>
        </Stack>

        <MercuryTableProvider
          columns={subscriptionsTable}
          queryKey={settingsKeys.transactions()}
          tableEndpoint={() => () => `mercury/orgs/quoins/transactions/${user.client_access_id}`}
          dataTransform={basicTableNormalize}
          initialState={{
            order: 'created_on',
            'order-dir': 'desc',
          }}
          basic
        >
          <TableWrapper />
        </MercuryTableProvider>
      </MercuryCol>
    </MercuryGrid>
  );
};

export default SubscriptionsInfo;
